import axios, {AxiosResponse} from 'axios'
import {ID, WatanTelecomResponse, Response} from '../../../../../_metronic/helpers'
import {CompanyCode, CompanyCodesQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const COMPANYCODE_URL = `${API_URL}/admin/companycodes`
const GET_COMPANYCODES_URL = `${API_URL}/admin/companycodes`

const getCompanyCodes = (query: string): Promise<CompanyCodesQueryResponse | WatanTelecomResponse<CompanyCode[]>> => {
  return axios
    .get(`${GET_COMPANYCODES_URL}?${query}`)
    .then((d: AxiosResponse<WatanTelecomResponse<CompanyCode[]>>) => {
      return d.data
    })
}

const getCompanyCodeById = async (id: ID): Promise<CompanyCode> => {
  try {
    const {data} = await axios.get(`${COMPANYCODE_URL}/${id}`)
    return data?.data?.company_code
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${COMPANYCODE_URL}/${id}`).then((response: AxiosResponse<CompanyCode>) => response.data)
}
const createCompanyCode = (companycode: CompanyCode): Promise<CompanyCode | undefined> => {
  return axios
    .post(COMPANYCODE_URL, companycode)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<CompanyCode>) => response.data)
}

const updateCompanyCode = (companycode: CompanyCode): Promise<CompanyCode | undefined> => {
  return axios
    .put(`${COMPANYCODE_URL}/${companycode.id}`, companycode)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<CompanyCode>) => response.data)
}

const deleteCompanyCode = (companycodeId: ID): Promise<void> => {
  return axios.delete(`${COMPANYCODE_URL}/${companycodeId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedCompanyCodes = (companycodeIds: Array<ID>): Promise<void> => {
  const requests = companycodeIds.map((id) => axios.delete(`${COMPANYCODE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getCompanyCodes,
  deleteCompanyCode,
  deleteSelectedCompanyCodes,
  getCompanyCodeById,
  createCompanyCode,
  updateCompanyCode,
}
