import { ID, WatanTelecomResponse, Response } from '../../../../../_metronic/helpers'
import { Country } from '../../../country/countries-list/core/_models'
import { ServiceCategory } from '../../../service-category/service-categories-list/core/_models'
// import { District, Province } from '../../../service/services-list/core/_models'
export type Company = {
  id?: any
  company_name?: string
  company_logo: string
  country: Country
}
export type Service = {
  id?: any
  service_name?: string
  service_category?: ServiceCategory
  company?: Company
  updated_at?: Date
  created_at?: Date
}
export type ServicesQueryResponse = WatanTelecomResponse<Service[]>

export const initialService: Service = {
  id: '',
  service_name: '',
  service_category: {
    id: '',
    category_name: ''
  },
  company: {
    id: '',
    company_name: "",
    company_logo: "",
    country: {
      country_name: '',
      id: '',
      language_id: 1,
      currency: {
        id: '',
        name: '',
        code: '',
        country_id: 1,
        exchange_rate_per_usd: '',
      },
    }
  }
}
