import axios, {AxiosResponse} from 'axios'
import {ID, WatanTelecomResponse, Response} from '../../../../../_metronic/helpers'
import {ServiceCategory, ServiceCategoriesQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const SERVICECATEGORY_URL = `${API_URL}/admin/service_categories`
const GET_SERVICECATEGORIES_URL = `${API_URL}/admin/service_categories`

const getServiceCategories = (query: string): Promise<ServiceCategoriesQueryResponse |  WatanTelecomResponse<ServiceCategory[]>> => {
  return axios
    .get(`${GET_SERVICECATEGORIES_URL}?${query}`)
    .then((d: AxiosResponse<WatanTelecomResponse<ServiceCategory[]>>) => {
      return d.data
    })
}

const getServiceCategoryById = async (id: ID): Promise<ServiceCategory> => {
  try {
    const {data} = await axios.get(`${SERVICECATEGORY_URL}/${id}`)
    return data?.data?.servicecategory
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${SERVICECATEGORY_URL}/${id}`).then((response: AxiosResponse<ServiceCategory>) => response.data)
}
const createServiceCategory = (service_category: ServiceCategory): Promise<ServiceCategory | undefined> => {
  return axios
    .post(SERVICECATEGORY_URL, service_category)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<ServiceCategory>) => response.data)
}

const updateServiceCategory = (service_category: ServiceCategory): Promise<ServiceCategory | undefined> => {
  return axios
    .put(`${SERVICECATEGORY_URL}/${service_category.id}`, service_category)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<ServiceCategory>) => response.data)
}

const deleteServiceCategory = (service_categoryId: ID): Promise<void> => {
  return axios.delete(`${SERVICECATEGORY_URL}/${service_categoryId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedServiceCategories = (service_categoryIds: Array<ID>): Promise<void> => {
  const requests = service_categoryIds.map((id) => axios.delete(`${SERVICECATEGORY_URL}/${id}`))
  return axios.all(requests).then(() => {
    toast.success('Action completed',{position:'top-right'})
  })
}

export {
  getServiceCategories,
  deleteServiceCategory,
  deleteSelectedServiceCategories,
  getServiceCategoryById,
  createServiceCategory,
  updateServiceCategory,
}
