import axios, {AxiosResponse} from 'axios'
import {ID, WatanTelecomResponse, Response} from '../../../../../_metronic/helpers'
import {Country, CountriesQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const COUNTRY_URL = `${API_URL}/admin/countries`
const GET_COUNTRIES_URL = `${API_URL}/admin/countries`

const getCountries = (query: string): Promise<CountriesQueryResponse | WatanTelecomResponse<Country[]>> => {
  return axios
    .get(`${GET_COUNTRIES_URL}?${query}`)
    .then((d: AxiosResponse<WatanTelecomResponse<Country[]>>) => {
      return d.data
    })
}

const getCountryById = async (id: ID): Promise<Country> => {
  try {
    const {data} = await axios.get(`${COUNTRY_URL}/${id}`)
    return data?.data?.country
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${COUNTRY_URL}/${id}`).then((response: AxiosResponse<Country>) => response.data)
}
// const createCountry = (country: Country): Promise<Country | undefined> => {
//   return axios
//     .post(COUNTRY_URL, country)
//     .then((response: AxiosResponse<Response<Country>>) => response.data)
//     .then((response: Response<Country>) => response.data)
// }

// const updateCountry = (country: Country): Promise<Country | undefined> => {
//   return axios
//     .put(`${COUNTRY_URL}/${country.id}`, country)
//     .then((response: AxiosResponse<Response<Country>>) => response.data)
//     .then((response: Response<Country>) => response.data)
// }
const createCountry = (country: Country): Promise<Country | undefined> => {
  
  const formData = new FormData();
  Object.entries(country).forEach(([key, value]) => {
    // Handle image separately
      formData.append(key, value);
  });

  return axios
    .post(COUNTRY_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
      },
    })
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Country>) => response.data)
}

const updateCountry = (country: Country): Promise<Country | undefined> => {
  const newFormData = new FormData();
  Object.entries(country).forEach(([key, value]) => {
    // Handle image separately
      newFormData.append(key, value);
  });
  // newFormData.append('_method','put');
  return axios
    .post(`${COUNTRY_URL}/${country.id}`, newFormData,{
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
      },
    })
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Country>) => response.data)
}

const deleteCountry = (countryId: ID): Promise<void> => {
  return axios.delete(`${COUNTRY_URL}/${countryId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedCountries = (countryIds: Array<ID>): Promise<void> => {
  const requests = countryIds.map((id) => axios.delete(`${COUNTRY_URL}/${id}`))
  return axios.all(requests).then(() => {
    toast.success("Action completed...",{position:'top-right'})

  })
}

export {
  getCountries,
  deleteCountry,
  deleteSelectedCountries,
  getCountryById,
  createCountry,
  updateCountry,
}
