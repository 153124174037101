import {ID, WatanTelecomResponse, Response} from '../../../../../_metronic/helpers'
// import { District, Province } from '../../../service-category/service-categories-list/core/_models'
export type ServiceCategory = {
  id?: any
  category_name?: string
  type?:string
  updated_at?: Date
  created_at?: Date
}

export type ServiceCategoriesQueryResponse = WatanTelecomResponse<ServiceCategory[]>

export const initialServiceCategory: ServiceCategory = {
  id: '',
  category_name: '',
}
