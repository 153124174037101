import axios, {AxiosResponse} from 'axios'
import {ID, WatanTelecomResponse, Response} from '../../../../../_metronic/helpers'
import {Language, LanguagesQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const LANGUAGE_URL = `${API_URL}/admin/languages`
const GET_LANGUAGES_URL = `${API_URL}/admin/languages`

const getLanguages = (query: string): Promise<LanguagesQueryResponse | WatanTelecomResponse<Language[]>> => {
  return axios
    .get(`${GET_LANGUAGES_URL}?${query}`)
    .then((d: AxiosResponse<WatanTelecomResponse<Language[]>>) => {
      return d.data
    })
}

const getLanguageById = async (id: ID): Promise<Language> => {
  try {
    const {data} = await axios.get(`${LANGUAGE_URL}/${id}`)
    return data?.data?.language
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${LANGUAGE_URL}/${id}`).then((response: AxiosResponse<Language>) => response.data)
}
const createLanguage = (language: Language): Promise<Language | undefined> => {
  return axios
    .post(LANGUAGE_URL, language)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Language>) => response.data)
}

const updateLanguage = (language: Language): Promise<Language | undefined> => {
  return axios
    .put(`${LANGUAGE_URL}/${language.id}`, language)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Language>) => response.data)
}

const deleteLanguage = (languageId: ID): Promise<void> => {
  return axios.delete(`${LANGUAGE_URL}/${languageId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedLanguages = (languageIds: Array<ID>): Promise<void> => {
  const requests = languageIds.map((id) => axios.delete(`${LANGUAGE_URL}/${id}`))
  return axios.all(requests).then(() => {
    toast.success("Action completed...",{position:'top-right'})

  })
}

export {
  getLanguages,
  deleteLanguage,
  deleteSelectedLanguages,
  getLanguageById,
  createLanguage,
  updateLanguage,
}
