import {ID, WatanTelecomResponse, Response} from '../../../../../_metronic/helpers'
export type Advertisement = {
  id?: any
  advertisement_title?: string
  ad_slider_image_url?: any
  status?: any
  updated_at?: Date
  created_at?: Date
}

export type AdvertisementsQueryResponse = WatanTelecomResponse<Advertisement[]>

export const initialAdvertisement: Advertisement = {
  id: 1,
  advertisement_title: "",
  ad_slider_image_url: "https://etisalat.af/images/images/EtisalatRewards.jpg",
  status: 0,
}
